<template>
  <section class="main-wrapper">
    <router-view/>
  </section>
</template>

<script>
export default {
  name: 'empty',
}
</script>

<style lang="scss" scoped>
.main-wrapper { 
  background-color: #000; 
  min-height: 100vh;
  min-width: 100vw;
}
</style>
